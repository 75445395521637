import React from 'react';
import logo from '../assets/img/hytex_wh.png'

const NavBar = () => {
  return (
    <>
      <nav className="mx-auto bg-transparent border-gray-200 dark:bg-gray-900">
        <div className="flex flex-wrap items-center justify-between mx-auto ">
          <img src={logo} className="h-12" alt="Hytex" />
            <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
              
                    {/* <button data-collapse-toggle="navbar-cta" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100         focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-cta" aria-expanded="false">
                      <span className="sr-only">Open main menu</span>
                      <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                      </svg>
                    </button> */}
            </div>
            {/* <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-cta">
            {/* Put <ul> and <li> here if needed 
          </div> */}
        </div>
      </nav>
    </>
  );
}

export default NavBar;
