'use client';
import React from 'react';
import logo from '../assets/img/hytex_wh.png'


const Footer = () => {
    return (
      <>
      {/* <div className="py-8 px-2 mx-auto max-w-screen-xl lg:py-8 grid md:grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-center"> */}
        <div className="w-full  p-6 sm:p-8 bg-transparent text-white rounded-lg shadow-xl">
            <div className="grid grid-cols-1 lg:grid-cols-3 pb-0 mb-0 mt-5 border-top">
                
                  <div className="logo-wrapper">
                    <img className="logo h-12 lg:h-20 mx-auto" src={logo} alt='hytex'/>
                  </div>   
                  <div className="info text-center text-lg-start">
                    <div className="info-col justify-left">
                        <div className="font-medium">Hytex</div>
                        205 Hooper Road<br/>
                        Suite 200<br/>
                        Wylie, TX 75098<br/><br/>
                    </div>
                  </div> 
                    <div className="info text-center text-lg-start">
                      <div className="info-col">
                        <div className="font-medium">Email</div>
                        <a href="mailto:sales@hytexrobotics.com" >info@hytexrobotics.com</a>
                        <br/><br/>
                        <div className="font-medium">Phone</div>
                        972-544-5461<br/>
                      </div>        
                    </div>
                {/* <div className="grid grid-cols-1 mx-auto flex justify-center items-center">
                  <div className="text-muted text-center" >&copy;2024 Hytex - All rights reserved.</div>
                </div>     */}
               
        </div>
      </div>

  </>
    );
    }

export default Footer;