import React from 'react';
import NavBar from '../components/navbar';
import Body from '../components/body';
import Footer from '../components/footer'

function Home() {
    
    return (
      <>
      <div className='App'>
        <div className="md:container md:mx-auto ">
          <div className="max-w-[1200px] mx-auto p-6 p-sm-6 ">
          <NavBar />
          <Body />
          <Footer />
          </div>
        </div>
      </div>
      </>
    );
}

export default Home;