import Home from "./views/home";


function App() {
  return (
    <div className="">
      <header className="">
        <Home />
      </header>
    </div>
  );
}

export default App;
