'use client';
import React, { useState } from 'react';
import axios from 'axios';


const Body = () => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        company: '',
        email: '',
        phone: '',
        country: '',
        contactInfo: true,
        demo: true,
    });

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChk = (e) => {
        const { name, checked } = e.target; // Destructure name and checked from the event target
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: checked, // Use computed property names to dynamically set the property based on the checkbox name
        }));
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        try {
            // console.log(formData);
            const response = await axios.post('https://modex-flask-api-brhbg.ondigitalocean.app/attendees', formData);
            // const response = await axios.post('http://127.0.0.1:5000/attendees', formData);
            console.log('Form submitted:', response.data);
            handleShow(); // Show the modal on successful submission
            // Reset the form fields after submission
            setFormData({
                firstname: '',
                lastname: '',
                company: '',
                email: '',
                phone: '',
                country: '',
                contactInfo: true,
                demo: true,
            });
        } catch (error) {
            console.error('Form submission error:', error);
            // Optionally handle the error state here, also with a modal or a message
        }
    };

    const modalVisibility = show ? '' : 'hidden'; // Control visibility state for both modal and backdrop
    const modalClass = `overflow-y-auto overflow-x-hidden top-80 fixed inset-0 z-50 justify-center items-center ${modalVisibility}`; // Center modal
    const backdropStyle = `fixed inset-0 bg-gray-900 bg-opacity-50 z-40 ${modalVisibility}`; // Fade-out effect for backdrop


    return (
      <section className=" dark:bg-gray-900">
        <div className="py-8 px-2 mx-auto max-w-screen-xl lg:py-8 grid md:grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
            <div className="flex flex-col justify-center">
                <div className="mb-12 text-center leading-normal text-3xl font-bold tracking-tight text-hytexwhite-900 dark:text-white">Automate your existing case picking operation!</div>
                <div className="mb-12 text-xl text-justify font-normal text-white dark:text-gray-400">ProPick is the world's first fully autonomous, drop in solution for case level order picking. ProPick picks and palletizes orders as it moves through the warehouse aisles, without the need to travel to a centralized picking station. Finished pallets are stretch wrapped onboard and delivered directly to your dock for loading. Driven by advanced software, ProPick is able to build superior pallets while being faster and more efficient than manual picking.</div>
                
            </div>
            <div>
                <div className="w-full lg:max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">
                      <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                          Schedule a ProPick demo or just get more information
                      </h2>
                      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-5 " >
                          <div className='form-box'>
                              <label htmlFor="firstname" className="form-label">First Name</label>
                              <input type="text" name="firstname" id="firstname" className="form-field" placeholder="First Name" required onChange={handleChange} value={formData.firstname}  />
                          </div>
                          <div className='form-box'>
                              <label htmlFor="lastname" className="form-label">Last Name</label>
                              <input type="text" name="lastname" id="lastname" className="form-field" placeholder="Last Name" required  onChange={handleChange} value={formData.lastname} />
                          </div>
                          <div className='form-box'>
                              <label htmlFor="company" className="form-label">Company Name</label>
                              <input type="text" name="company" id="company" className="form-field" placeholder="Company" required onChange={handleChange} value={formData.company}  />
                          </div>
                          <div className='form-box'>
                              <label htmlFor="email" className="form-label">Email Address</label>
                              <input type="email" name="email" id="email" className="form-field" placeholder="Email Address" required  onChange={handleChange}  value={formData.email}/>
                          </div>
                          <div className='form-box'>
                              <label htmlFor="phone" className="form-label">Phone number</label>
                              <input type="tel" name="phone" id="phone" className="form-field" placeholder="Phone Number" required  onChange={handleChange} value={formData.phone} />
                          </div>
                          <div className='form-box'>
                              <label htmlFor="country" className="form-label">Country</label>
                              <input type="text" name="country" id="country" className="form-field" placeholder="Country" required  onChange={handleChange}  value={formData.country} />
                          </div>
                          
                            <div className="flex items-center col-span-2 sm:col-span-1">
                              <input id="contactInfo"  aria-describedby="contactInfo" name="contactInfo" type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" onChange={handleChk} checked={formData.contactInfo} />
                              <label htmlFor="contactInfo" className="text-sm ml-3 font-medium text-gray-500 dark:text-gray-400">Contact me with more information</label>
                            </div>
                          
                            <div className="flex items-center col-span-2 sm:col-span-1">
                              <input id="demo" aria-describedby="demo" name="demo" type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" onChange={handleChk} checked={formData.demo} />
                              <label htmlFor="demo" className="text-sm ml-3 pr-2 font-medium text-gray-500 dark:text-gray-400">I'm interested in scheduling a demo</label>
                            </div>
                          
                          <div className="col-span-2 flex justify-left "><button data-modal-target="popup-modal" data-modal-toggle="popup-modal" type="submit" className="px-3 py-1 text-base font-medium text-center text-white bg-hytexred-700 rounded-lg hover:bg-hytexred-900 focus:ring-4 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                          {/* <button
    className="g-recaptcha hidden"
    data-sitekey="6LenSJIpAAAAAOLHZlVg7gUDH28GhrXauZicLJs7"
    data-callback="onSubmit"
    data-action="submit"
>
    Submit
</button> */}
                          </div>
                        </form>                    
                        <div className={backdropStyle}></div>
<div id="popup-modal" tabIndex="-1" className={modalClass}>
    <div className="relative p-4 w-full max-w-md h-full mx-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Your contact information has been submitted. <br/>We will be in touch soon</h3>
                <button data-modal-hide="popup-modal" type="button" className="text-white bg-hytexred-700 hover:bg-hytexred-900 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={handleClose}>
                    OK
                </button>                                        
            </div>
        </div>
    </div>
</div>

                </div>
            </div>
        </div>
      </section>
    
    );
    }

export default Body;